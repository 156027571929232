
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import chart from '@/views/calculator/components/chart.vue';
import * as OM from '@/Model';
import * as VM from '@/viewModel';

@Options({
    components: {
        chart
    }
})
export default class SummaryCharts extends Vue {

    @Prop() model: OM.SummaryVM;

    created(){}

    get companyManagementScoringCat() {
        return VM.ScoringCategories.CompanyManagement;
    }
    get productScoringCat() {
        return VM.ScoringCategories.Product;
    }
    get socialResponsabilityScoringCat() {
        return VM.ScoringCategories.SocialResponsibility;
    }

}
